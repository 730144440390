/**
 * Component short description
 *
 * @see StaySearch.md for details
 */

/**
 * Imports React and third party packages
 */
import React, { useState, useEffect } from "react";
import clsx from "clsx";

/**
 * Imports other components and hooks
 */
import Pagination from "../../Pagination/Pagination";
import StayFilters from "../StayFilters/StayFilters";
import { useFilters, useUpdateEffect } from "../../../hooks";
import FilteredBy from "../../FilteredBy";
import {
  useAccomodationTypesSwr,
  useRoomTypesSwr,
  useExtrasSwr,
  useStaysSwr,
  useMealsSwr,
} from "../Stay/Stay.logic";
import { useDestinationsSimpleSwr } from "../../_destination/Destination/Destination.logic";
import SortBy from "../../SortBy/SortBy";
import AccommodationAsThumb from "../../_accommodation/AccommodationAsThumb";

/**
 * Imports data
 */
import { propTypes, defaultProps } from "./StaySearch.data";

/**
 * Imports Material UI components
 */
import Grid from "@material-ui/core/Grid";

/**
 * Imports translations
 */
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { ro_ro } from "./StaySearch.lang.ro-ro";
import { hu_hu } from "./StaySearch.lang.hu-hu";
import { en_us } from "./StaySearch.lang.en-us";
import { de_de } from "./StaySearch.lang.de-de";

i18n.addResourceBundle("ro-RO", "StaySearch", ro_ro);
i18n.addResourceBundle("hu-HU", "StaySearch", hu_hu);
i18n.addResourceBundle("en-US", "StaySearch", en_us);
i18n.addResourceBundle("de-DE", "StaySearch", de_de);

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const StaySearch = (props) => {
  const { stays: defaultStays } = props;
  const { i18n } = useTranslation("StaySearch");
  const { language } = i18n;

  const [stays, setStays] = useState(defaultStays);
  const { total, limit, data } = stays;

  const { filters, setData, parseFiltersForQuery, setFilters } = useFilters();
  const { page } = filters;

  /**
   * Fetches the destinations
   */
  const {
    data: destinations,
    mutate: mutateDestinations,
  } = useDestinationsSimpleSwr();

  /**
   * Fetches the accomodation types
   */
  const {
    data: accommodationTypes,
    mutate: mutateAccomodationTypes,
  } = useAccomodationTypesSwr();

  /**
   * Fetches the room types
   */
  const { data: roomTypes, mutate: mutateRoomTypes } = useRoomTypesSwr();

  /**
   * Fetches the extras
   */
  const { data: extras, mutate: mutateExtras } = useExtrasSwr();

  /**
   * Fetches the meals
   */
  const { data: meals, mutate: mutateMeals } = useMealsSwr();

  /**
   * Fetches the accomodations
   */
  const { data: accomodationsData, mutate: mutateAccomodations } = useStaysSwr({
    filters: parseFiltersForQuery(filters),
  });

  useEffect(() => {
    if (destinations && accommodationTypes && roomTypes && extras && meals) {
      setData({
        destinations,
        accommodationTypes,
        roomTypes,
        extras,
        meals,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinations, accommodationTypes, roomTypes, extras, meals]);

  useUpdateEffect(() => {
    mutateDestinations();
    mutateAccomodationTypes();
    mutateRoomTypes();
    mutateExtras();
    mutateAccomodations();
    mutateMeals();
  }, [language]);

  useEffect(() => {
    if (accomodationsData) {
      setStays(accomodationsData);
    }
  }, [accomodationsData]);

  const handlePagination = (_event, value) => {
    const offset = (value - 1) * limit;
    setFilters((prevState) => ({
      ...prevState,
      page: value,
      offset: offset,
      limit: limit,
    }));
  };

  const stayList = data.map((item) => {
    const { id } = item;

    return (
      <Grid item xs={12} md={6} key={id}>
        <AccommodationAsThumb {...item} />
      </Grid>
    );
  });

  return (
    <Grid container>
      <Grid item xs={12} md={5} lg={4} className={clsx("StaySearch")}>
        <StayFilters />
      </Grid>
      <Grid container item xs={12} md={7} lg={8} spacing={2}>
        <Grid item xs={10}>
          <FilteredBy />
        </Grid>
        <Grid item xs={12} md={2}>
          <SortBy />
        </Grid>
        <Grid container spacing={2} item xs={12}>
          {stayList}
        </Grid>
        <Grid item container xs={12} justify="flex-end">
          <Pagination
            page={page}
            total={total / limit}
            onChange={handlePagination}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

StaySearch.propTypes = propTypes;
StaySearch.defaultProps = defaultProps;

export default StaySearch;
export {
  propTypes as StaySearchPropTypes,
  defaultProps as StaySearchDefaultProps,
};
